import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av Collapse`}</h2>
    <p>{`Komponent for å enkelt lage ekspanderbart område.`}</p>
    <p>{`Merk at komponenten er avhengig av å få angitt transition styling for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`height`}</code>{`.
Dette gjøres helst ved å importere less-filen fra pakkens less-mappe.
Alternativt kan man gjøre det ved å selv legge styling i klassen
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.ffe-collapse-transition`}</code>{`, legge det på en egen klasse og sende inn via
property `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`className`}</code>{` eller style det direkte via property `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`style`}</code>{`.`}</p>
    <h2>{`Collapse`}</h2>
    <p>{`Hvorvidt området er ekspandert eller ikke styres av konsumenten via property `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isOpen`}</code>{`.`}</p>
    <DSExample name="collapse_Collapse" mdxType="DSExample" />
    <p>{`Et callback kan gis via property `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onRest`}</code>{` som kjøres når transisjonen er ferdig.`}</p>
    <DSExample name="collapse_Collapse_onRest" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      